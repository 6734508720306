import { redirect } from 'vike/abort'
import { modifyUrl } from 'vike/modifyUrl'
import type { PageContext } from 'vike/types'

import { extractLanguageFromPathname } from '#/i18n/extractLanguageFromPathname'
import type { Language } from '#/i18n/languages'

import { extractLanguageFromHeader } from '../i18n/extractLanguageFromHeader'

export function onBeforeRoute(pageContext: PageContext): {
  pageContext: {
    language: Language
    urlLogical: string
  }
} | void {
  const { pathname } = pageContext.urlParsed

  if (pathname.startsWith('/api') || pathname.startsWith('/_')) {
    return
  }

  const { language, pathnameWithoutLanguage } = extractLanguageFromPathname(
    pageContext.urlParsed.pathname,
  )

  if (!language) {
    const acceptLanguageHeader = pageContext.headers?.['accept-language']
    const language = extractLanguageFromHeader(acceptLanguageHeader)

    const urlWithLanguage = modifyUrl(pageContext.urlParsed.href, {
      pathname: `/${language}${pageContext.urlParsed.pathname}`,
    })

    throw redirect(urlWithLanguage)
  }

  const urlWithoutLanguage = modifyUrl(pageContext.urlPathname, {
    pathname: pathnameWithoutLanguage,
  })

  return { pageContext: { language, urlLogical: urlWithoutLanguage } }
}
