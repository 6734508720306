import { isValidlangugage } from '#/i18n/isValidlangugage'

import { parseAcceptHeader } from './parseAcceptHeader'

export function extractLanguageFromHeader(
  acceptLanguageHeader: string | undefined,
) {
  const acceptHeaderLocales = parseAcceptHeader(acceptLanguageHeader)

  const locale =
    acceptHeaderLocales
      ?.map(({ locale }) => locale.slice(0, 2))
      ?.find((language) => isValidlangugage(language)) ?? 'en'

  return locale
}
