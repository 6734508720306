export const parseAcceptHeader = (acceptHeader: string | undefined) => {
  const options = acceptHeader
    ?.split(',')
    .map((option) => {
      const [locale, q] = option.split(';q=').map((s) => s.trim())
      return {
        locale: locale!,
        q: q ? parseFloat(q) : 1,
      }
    })
    .filter((option) => typeof option.locale === 'string')

  options?.sort((a, b) => b.q - a.q)
  return options
}
